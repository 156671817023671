import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomePage from "./Pages/Home.jsx";
import Login2 from "./Components/Page Components/Login2/Login";
import FixedBottomNavigation from "./Components/BottomNav/Bottomnav";
import RideDetailsComponent from "./Components/Page Components/RideDetails/RideDetails";
import PolicyComponent from "./Components/Page Components/Policy/Policy";
import RescheduleComponent from "./Components/Page Components/Reschedule/Reschedule";

const router = createBrowserRouter([
  { path: "/", element: <Login2 /> },
  { path: "/home", element: <HomePage /> },
  { path: "/bottomnav", element: <FixedBottomNavigation /> },
  { path: "/ride-details/:id", element: <RideDetailsComponent /> },
  { path: "/reschedule", element: <RescheduleComponent /> },
  { path: "/policy", element: <PolicyComponent /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
