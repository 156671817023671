import {useLocation, useNavigate} from 'react-router-dom';
import {RidesHeaderComponent} from "../../Header/Header";
import {Button, Card, CardActionArea, Typography} from "@mui/material";
import {
    ArrowForwardOutlined,
    BusinessOutlined, CallOutlined,
    EastOutlined, ErrorOutlineOutlined,
    FitnessCenterOutlined,
    HomeOutlined, NearMeOutlined, SchoolOutlined
} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import './RideDetails.css'
import Swal from "sweetalert2";
import subscriptionService from "../../../Services/subscription.service";
import driverService from "../../../Services/driver.service";
import loconavService from "../../../Services/loconav.service";


export default function RideDetailsComponent(){
    const location = useLocation();
    const ride = location.state.ride;
    const trip = location.state.index +1;
    console.log(ride)
    const queryParams = new URLSearchParams(location.search);
    const queryOffset = queryParams.get('offset');

    const navigate = useNavigate();


    const getIcon = (iconName) => {
        switch(iconName.toLowerCase()) {
            case 'home':
                return <HomeOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'office':
                return <BusinessOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'gym':
                return <FitnessCenterOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'school':
                return <SchoolOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            // Add more cases here if you have more icons
            default:
                return <ErrorOutlineOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
        }
    };
    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const rideDateTime = new Date(ride.ride_date_time);
            const diffInMilliseconds = rideDateTime - now;
            const diffInMinutes = Math.round(diffInMilliseconds / (1000 * 60));

            setTimeLeft(diffInMinutes > 0 ? diffInMinutes : 0);
        }, 1000);

        return () => clearInterval(intervalId); // Clear the interval when the component unmounts
    }, [ride.ride_date_time]);
    function formatTime(minutes) {
        if (minutes < 60) {
            return `${minutes} Mins`;
        } else if (minutes < 1440) {
            return `${Math.floor(minutes / 60)} Hour${minutes >= 120 ? 's' : ''}`;
        } else {
            return `${Math.floor(minutes / 1440)} Day${minutes >= 2880 ? 's' : ''}`;
        }
    }

    const handleCancelRide = () => {
        return () => {
            Swal.fire({
                title: 'Cancel Ride',
                text: "Cancellation window opens 10 hrs to 30 min before the ride",
                info: "Do you want to proceed?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'rgba(234,33,33,0.95)',
                cancelButtonColor: 'rgb(21,116,222)',
                confirmButtonText: 'Proceed',
                cancelButtonText: 'Go Back',
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Processing...',
                        html: 'Please wait while we cancel your ride...', // Add a loading spinner here if you want
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    subscriptionService.cancelRide(ride.id) // ID of the ride to be cancelled
                        .then(() => {
                            Swal.close();
                            Swal.fire(
                                'Cancelled!',
                                'Your Ride has been cancelled.',
                                'success'
                            ).then(() => {
                                window.location.href = `/home?offset=${queryOffset}`;
                            });

                        })
                        .catch((error) => {
                            Swal.close();
                            console.error('Error cancelling ride:', error);
                            Swal.fire(
                                'Error!',
                                'There was an error cancelling your ride. Please try again.',
                                'error'
                            )
                        });
                }
            })
        }
    }

    const [driver, setDriver] = useState(null); // This will hold the driver details
    useEffect(() => {
        const fetchDriver = async () => {
            try {
                let driver = await driverService.getDriver(ride.driver_phone);
                setDriver(driver)
            }
            catch (error) {
                console.error('Error:', error);
            }
        }
        fetchDriver().then(r => console.log(r));
    }, [ride.driver_phone]);

    const [distance, setDistance] = useState(0);
    const [realtimeETA , setRealtimeETA] = useState(0);
    console.log(distance);
    useEffect(() => {
        const fetchETA = async () => {
            if (driver && ride) {
                try {
                    let response = await loconavService.getETA(driver.vehicle_number, ride.pickup_latitude, ride.pickup_longitude);
                    response = JSON.parse(response.replace(/\\/g, ''));
                    console.log(response)
                    let distance = response.data.elements[0].distance.humanReadable; // Extracting distance
                    setDistance(distance); // Set the distance
                    console.log(distance);
                    // Extract numeric value from distance string
                    let numericDistance = parseFloat(distance.split(' ')[0]);
                    // Multiply by 4, round it and set to realtimeETA
                    setRealtimeETA(Math.round(numericDistance * 4));
                }
                catch (error) {
                    console.error('Error:', error);
                }
            }
        }
        fetchETA();
    }, [driver, ride]);
    // console.log(Math.max(timeLeft, realtimeETA))

    // Now you can use the ride details in your component
    // ...

    let rescheduleStatus = ride.additional_ride_details ? ride.additional_ride_details.toLowerCase() : '';


    return(
        <div className="home-container">
            <RidesHeaderComponent ride={"true"} link={`/home?offset=${queryOffset}`} title={"Ride Detail"}/>
            {/* Display the ride details */}
            <div className="ride-detail-container">
                <div className="rides-detail-card-container">
                    <Card sx={{p: 2}} className="ride-detail-card">
                        <Typography sx={{color: "#132961", fontSize: 20}}>
                            Ride Scheduled for
                        </Typography>
                        <Typography sx={{color: "#132961", fontSize: 30, fontWeight: 700}}>
                            {new Date(ride.ride_date_time).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}
                        </Typography>
                        <Typography sx={{color: "#132961", fontSize: 23, fontWeight: 400}}>
                            {new Date(ride.ride_date_time).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                weekday: 'long'
                            })}
                        </Typography>
                        <div className="ride-detail-trip-container">
                            <Typography className="ride-detail-trip-number">
                                Trip {trip}
                            </Typography>
                            <ArrowForwardOutlined sx={{color: "#0049AF"}} className="ride-trip-icon"/>
                        </div>
                        <div className="ride-detail-location-icon-container">
                            <div className="ride-detail-trip-address-card-title-container" style={{paddingLeft: "0px"}}>
                                {getIcon(ride.pickup_address_type)}
                                <Typography className="ride-detail-trip-address-card-title-text"
                                            sx={{fontSize: "15px"}}>
                                    {ride.pickup_address_type}
                                </Typography>
                            </div>
                            <div className="ride-detail-card-data-direction-icon">
                                <EastOutlined sx={{color: "#132961"}}></EastOutlined>
                            </div>
                            <div className="ride-detail-trip-address-card-title-container no-border">
                                {getIcon(ride.drop_address_type)}
                                <Typography className="ride-detail-trip-address-card-title-text"
                                            sx={{fontSize: "15px"}}>
                                    {ride.drop_address_type}
                                </Typography>
                            </div>
                        </div>
                        <div className="ride-details-manage-button-container">
                            <Button
                                onClick={() => navigate(`/reschedule`, { state: { ride:ride, index:location.state.index } })}
                                key={ride.id}
                                className="ride-details-manage-button"
                                variant={"outlined"}
                                disabled={timeLeft > 720 || timeLeft < 60 || ride.ride_status.toLowerCase()==='rescheduled' || ['approved', 'reject'].includes(rescheduleStatus)}
                            >
                                Reschedule Ride
                            </Button>
                            <Button
                                className="ride-details-manage-button"
                                onClick={handleCancelRide()}
                                variant={"outlined"}
                                disabled={timeLeft > 720 || timeLeft < 60}
                            >
                                Cancel Ride
                            </Button>
                        </div>
                    </Card>
                    <div className="ride-tracking-container">
                        <Typography className="ride-tracking-text">
                            Your Ride is arriving in
                        </Typography>
                        <div className="ride-tracking-details">
                            <Typography sx={{color: "#FFF", fontSize: timeLeft !== null ? 35 : 25, fontWeight: "bold", width: "70%"}} className="ride-tracking-time">
                                {Math.max(timeLeft, realtimeETA) !== null ? formatTime(Math.max(timeLeft, realtimeETA)) : 'Calculating time...'}
                            </Typography>
                            <Button className="track-button" sx={{width: "20%"}} variant="outlined"
                                    onClick={() => window.open(driver.track_url, '_blank')}
                                    disabled={timeLeft >= 10}
                                    startIcon={<NearMeOutlined/>}>

                                Track
                            </Button>
                        </div>
                    </div>
                    <div className="ride-details-driver-info-container">
                        <Card sx={{p: 2}}>
                            <Typography sx={{color: "#132961", fontSize: 22, fontWeight: 500, mb: 2}}>
                            Driver & Vehicle Info
                            </Typography>
                            <Typography className="ride-details-driver-info-header">
                                Driver Name
                            </Typography>
                            <Typography className="ride-details-driver-info-content" sx={{mb: 2}}>
                                {driver ? driver.name : 'Loading...'}
                            </Typography>
                            <div className="driver-mobile-container">
                                <div className="driver-mobile-info">
                                    <Typography className="ride-details-driver-info-header">
                                        Mobile Number
                                    </Typography>
                                    <Typography className="ride-details-driver-info-content" sx={{mb: 2}}>
                                        {ride.driver_phone}
                                    </Typography>
                                </div>
                                <a href={`tel:${ride.driver_phone}`} style={{textDecoration: 'none'}}>
                                    <Button variant="contained" className="call-button" startIcon={<CallOutlined/>}>
                                        Call Driver
                                    </Button>
                                </a>
                            </div>
                            <Typography className="ride-details-driver-info-header">
                                Vehicle Number
                            </Typography>
                            <Typography className="ride-details-driver-info-content" sx={{fontWeight:"800", mb:2}}>
                                {driver ? driver.vehicle_number : 'Loading...'}
                            </Typography>
                        </Card>
                    </div>
                    <div className="policy-card-container">
                        <CardActionArea onClick={() => navigate(`/policy`, { state: { ride:ride,returnTo:'ride-details', index:location.state.index } })}
                                        key={ride.id}>
                            <Card sx={{p:2}} className="policy-card">
                                <Typography>
                                    View Reschedule & Cancel Ride Policy
                                </Typography>
                                <ArrowForwardOutlined sx={{color: "#0049AF"}} className="ride-trip-icon"/>
                            </Card>
                        </CardActionArea>
                    </div>
                </div>
            </div>
        </div>
    )
}
