import Card from "../../Card/Card";
import React, {useEffect, useState} from "react";
import './UpcommingRides.css'
import subscriptionService from "../../../Services/subscription.service";
import {CardActionArea} from "@mui/material";
import { useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

function sortRides(rides) {
    return rides.sort((a, b) => {
        const dateA = new Date(a.ride_date_time);
        const dateB = new Date(b.ride_date_time);
        return dateA.getTime() - dateB.getTime();
    });
}

export default function UpcomingRidesComponent(props){
    const navigate = useNavigate();
    const date = new Date();
    const currentDate= (date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })); // Set the current date

    const propsWeek= props.week;
    const propsStartDate= props.startDate;
    const propsOffset= props.offset;
    console.log({propsWeek, propsStartDate, propsOffset})

    let dates = [];

    let startDay = new Date(propsStartDate);

    // Check if today is Sunday
    // if (startDay.getDay() === 0) {
    //     // If today is Sunday, find the next Monday
    //     startDay.setDate(startDay.getDate() + 1);
    // } else {
    //     // If today is not Sunday, find the most recent Monday
    //     startDay.setDate(startDay.getDate() - ((startDay.getDay() + 6) % 7));
    // }

    for (let i = 0; i < 6; i++) { // Loop for 6 days
        let dateObj = new Date(startDay.getTime());
        dateObj.setDate(dateObj.getDate() + i);

        let day = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
        let date = String(dateObj.getDate()).padStart(2, '0'); // Get the day of the month and pad it with zeros

        day= day.slice(0, 3).toUpperCase();

        dates.push({
            id: i,
            date: date,
            day: day
        });
    }
    const [rides, setRides] = useState([]);

    useEffect(() => {
        // Check if the data is in localStorage
        const cachedData = localStorage.getItem('ridesData');
        const cachedTimestamp = localStorage.getItem('ridesTimestamp');

        const currentTime = Date.now();
        const thirtySeconds = 30 * 1000; // 60 seconds * 1000 milliseconds
        if (cachedData) {
            // If the data is in localStorage, use it
            setRides(JSON.parse(cachedData));
        }

        if (cachedData && cachedTimestamp && currentTime - cachedTimestamp < thirtySeconds) {
            // If the data is in localStorage and it's not older than 1 minute, use it
            setRides(JSON.parse(cachedData));
        } else {
            // If the data is not in localStorage or it's older than 1 minute, fetch it from the API
            subscriptionService.getRides().then(data => {
                if(data!=='SERVER ERROR'){
                    console.log(data);
                    if (Array.isArray(data.active_subscription_rides)) {
                        const sortedRides = sortRides(data.active_subscription_rides);
                        setRides(sortedRides);
                        // Store the data and the current timestamp in localStorage
                        localStorage.setItem('ridesData', JSON.stringify(sortedRides));
                        localStorage.setItem('ridesTimestamp', currentTime.toString());
                    } else {
                        console.error('Error: expected an array but received', data);
                    }

                }
                else {
                    console.log('SERVER ERROR');
                    Swal.fire({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong!',
                    })
                }

            });
        }
    }, []); // Empty dependency array means this effect will only run once, when the component mounts


    let nextMonday = new Date();
    nextMonday.setDate(nextMonday.getDate() + (1 + (7 - nextMonday.getDay()) % 7 || 7));

// Get the date for the Monday after the next
    let followingMonday = new Date(nextMonday);
    followingMonday.setDate(followingMonday.getDate() + 7);

// Check if there are any rides in the next week
    const areNextWeekRidesPresent = rides.some(ride => {
        const rideDate = new Date(ride.ride_date_time);
        // console.log({rideDate, nextMonday, followingMonday})
        return rideDate >= nextMonday && rideDate < followingMonday;
    });
    localStorage.setItem('areNextWeekRidesPresent', areNextWeekRidesPresent.toString());

    function handleCardClick(ride, navigate,index, props) {
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const currentHour = currentDate.getHours();

        if (!(currentDay === 0 && currentHour >= 20)) {
            if ((ride.ride_status.toLowerCase()) !== 'cancelled' && ride.ride_status.toLowerCase() !== 'completed' && ride.ride_status.toLowerCase() !== 'rejected') {
                navigate(`/ride-details/${ride.id}?offset=${props.offset}`, { state: { ride, index } });
            }
        }
    }
    return(
        <>
            {dates.map((dateObj, index) => {
                // Filter the rides that match the current date
                const ridesForTheDay = rides.filter(ride => {
                    const rideDate = new Date(ride.ride_date_time);
                    return rideDate.getDate() === Number(dateObj.date);
                });

                return (
                    <div className="day-view" key={dateObj.id}>
                        <div className="day-view-date-container">
                            <p className="day-view-weekday">{dateObj.day}</p>
                            <p className={`day-view-date ${dateObj.date === currentDate.slice(0,2) ? 'current-day' : ''}`}>
                                {dateObj.date}
                            </p>
                        </div>
                        <div className="day-view-card">
                            {ridesForTheDay.map((ride, index) => (
                                ride.driver_phone && (
                                    <CardActionArea
                                        onClick={() => handleCardClick(ride, navigate, index, props)}
                                        key={ride.id}
                                    >
                                        <Card
                                            pickupAddressType={ride.pickup_address_type}
                                            dropAddressType={ride.drop_address_type}
                                            rideTime={new Date(ride.ride_date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                                            vehicleNumber={ride.vehicle_number}
                                            tripNumber={index + 1}
                                            rideStatus={ride.ride_status.toLowerCase()}
                                            rescheduleStatus={ride.additional_ride_details ? ride.additional_ride_details.toLowerCase() : ''}
                                            // rideStatus={"completed"} //For Testing Dynamic Styles
                                        />
                                    </CardActionArea>
                                )
                            ))}
                        </div>
                    </div>
                );
            })}
        </>
    )
}